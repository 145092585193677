/* eslint no-undef: "off", no-console: "off" */

import './lib/ahoy';
import 'is-in-viewport';

const analyticsEnabled = window.gon && window.gon.global.advert.analyticsEnabled;

export const initTrackViews = () => {
  if (analyticsEnabled) {
    try {
      $('[data-event]').each(function () {
        const target = $(this);
        const data = target.data();

        if (!target.hasClass('initialized') && target.is(':in-viewport')) {
          ahoy.trackView(data.event, data.properties);
          target.addClass('initialized');
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
};

let timer;

$(window).scroll(function () {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => initTrackViews(), 300);
});
