/* eslint no-undef: "off", no-console: "off" */

import './lib/ahoy';

const analyticsEnabled = window.gon && window.gon.global.advert.analyticsEnabled;

export const initTrackClicks = () => {
  if (analyticsEnabled) {
    try {
      ahoy.trackClicks();
    } catch (e) {
      console.error(e);
    }
  }
};
